import * as React from "react"
import { Label } from "~/modules/common/ui/label"

import { cn } from "~/modules/common/util/shadcn"

export interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  label?: string
  error?: string
}

const Input = React.forwardRef<HTMLInputElement, InputProps>(({ className, type, error, label, ...props }, ref) => {
  return (
    <div className='prose'>
      {label && <Label>{label}</Label>}
      <input
        type={type}
        className={cn(
          "flex h-14 w-full rounded-md border border-slate-400 hover:border-slate-800 bg-transparent px-3 py-1 text-sm shadow-sm transition-colors file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-slate-500 focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-slate-950 disabled:cursor-not-allowed disabled:opacity-50 dark:border-slate-800 dark:placeholder:text-slate-400 dark:focus-visible:ring-slate-300",
          className,
          error ? "border-red-400" : "",
        )}
        ref={ref}
        {...props}
      />
      {error && <span className='text-red-500 text-sm mt-2'>{error}</span>}
    </div>
  )
})
Input.displayName = "Input"

export { Input }
